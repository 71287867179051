// @flow

import * as R from "ramda";

import { getChecklistFieldsById } from "src/reducers";
import { manageViewFieldWidthMap } from "src/constants/processInstanceColumns";
import * as atypes from "src/constants/actionTypes";
import { createSelector } from "reselect";

import type { ColumnId, FieldSizeMap, AppState, FieldsById } from "src/types";

type ActionType = {
  payload: {
    columnId: ColumnId,
    width: string
  },
  type: string
};

const getState = state => state;
const getOwnerFieldWidth = state => state["owner"];
const getDueDateFieldWidth = state => state["dueDate"];
const getStatusFieldWidth = state => state["status"];

const fieldSizeMap = (
  state: FieldSizeMap = manageViewFieldWidthMap,
  { payload, type }: ActionType
) => {
  switch (type) {
    case atypes.UPDATE_COLUMN_WIDTH:
      const value = `${payload.width}`.split("px")[0];
      const width = `${value}px`;
      return {
        ...state,
        [payload.columnId]: width
      };
    case atypes.UPDATE_FIELD_SIZE_FROM_REPORT:
      return payload;
    default:
      return state;
  }
};

/**
 * Get the width of a field
 *
 * @param {Object} state - current state of the app
 * @param {string} columnId - column id of the field
 *
 * @return {string} - width of the column in px
 */
export const getFieldSize = createSelector(
  [
    getState,
    state => getChecklistFieldsById(state),
    (state: AppState, columnId) => columnId
  ],
  (state: AppState, fieldsById: FieldsById, columnId: ColumnId) => {
    const fieldId = R.last(`${columnId}`.split("-"));
    // $FlowFixMe - Flow doesn't support optional chaining
    let fieldType: string = fieldsById?.get(`${fieldId}`)?.get("type");
    // If it's an embedded owner, status or dueDate field then set the
    // fieldType to "owner", "status" or "dueDate"
    if (["owner", "status", "dueDate"].includes(fieldId)) {
      fieldType = fieldId;
    }

    return (
      state.fieldSizeMap?.[`${columnId}`] ||
      manageViewFieldWidthMap[fieldType] ||
      manageViewFieldWidthMap[columnId]
    );
  }
);

/**
 * Get the widths of chatroom attributes (owner, status, dueDate)
 *
 * @param {Object} state - current state of fieldSizeMap
 * @param {string} columnId - column id of the linked field whose
 * attributes' sizes are requested
 *
 * @return {Object} - widths of owner, dueDate and status fields
 */
export const getChatroomAttributeWidth = createSelector(
  [getOwnerFieldWidth, getDueDateFieldWidth, getStatusFieldWidth],
  (ownerFieldWidth, dueDateFieldWidth, statusFieldWidth) => ({
    ownerFieldWidth,
    dueDateFieldWidth,
    statusFieldWidth
  })
);

export default fieldSizeMap;
