// @flow

import React, { useState, useEffect } from "react";

import Checkbox from "src/components/Checkbox.old";
import { Error } from "src/styles";
import { ChecklistSettings } from "src/styles/checklist";
import type { NumberFieldSettings } from "src/types";

type Props = {
  type: string,
  settings: NumberFieldSettings,
  saveSettings: Function
};

type State = {
  placeholder: ?string,
  max: ?number,
  min: ?number,
  step: ?number,
  defaultValue: ?number,
  suppressDefaultNotification: boolean
};

const Number = ({ settings, saveSettings }: Props) => {
  const [state, setState] = useState<State>({
    placeholder: "",
    max: null,
    min: null,
    step: null,
    defaultValue: null,
    suppressDefaultNotification: false
  });

  useEffect(() => {
    if (settings) {
      const {
        placeholder,
        max,
        min,
        step,
        defaultValue,
        suppressDefaultNotification
      } = settings;
      setState({
        placeholder,
        max,
        min,
        step,
        defaultValue,
        suppressDefaultNotification
      });
    }
  }, [settings]);

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (id === "placeholder") {
      setState(prevState => ({ ...prevState, [id]: value }));
    } else if (id === "step") {
      setState(prevState => ({ ...prevState, [id]: parseInt(value, 10) }));
    } else {
      setState(prevState => ({ ...prevState, [id]: parseFloat(value) }));
    }
  };

  useEffect(() => {
    saveSettings(state);
  }, [state]);

  const {
    placeholder,
    max,
    min,
    step,
    defaultValue,
    suppressDefaultNotification
  } = state;

  const handleSuppressDefaultNotification = (e: any) => {
    const { checked } = e.target;
    setState(prevState => ({
      ...prevState,
      suppressDefaultNotification: checked
    }));
  };

  return (
    <ChecklistSettings>
      <form>
        <div>
          <label htmlFor="placeholder">Placeholder</label>
          <input
            type="text"
            id="placeholder"
            value={placeholder}
            onChange={handleChange}
            autoFocus
          />
        </div>
        <div>
          <label htmlFor="max">Max</label>
          <input type="number" id="max" value={max} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="min">Min</label>
          <input type="number" id="min" value={min} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="step">Step</label>
          <input type="number" id="step" value={step} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="defaultValue">Default Value</label>
          <input
            type="number"
            id="defaultValue"
            value={defaultValue}
            onChange={handleChange}
          />
        </div>
        <div>
          <Checkbox
            handleChange={handleSuppressDefaultNotification}
            checked={suppressDefaultNotification}
            id="suppressDefaultNotification"
          >
            Supress notifications for default values
          </Checkbox>
        </div>
        <Error>
          {min && max && parseInt(min, 10) > parseInt(max, 10)
            ? "Max is less than Min"
            : null}
        </Error>
      </form>
    </ChecklistSettings>
  );
};

export default Number;
