// @flow

import moment from "moment";
import * as R from "ramda";
import {
  fork,
  race,
  put,
  takeEvery,
  take,
  call,
  select,
  actionChannel,
  delay,
  takeLatest
} from "redux-saga/effects";
import localforage from "localforage";

import * as chatroom from "src/api/chatroom";

import getAppState, {
  getUserMembership,
  getLastOrg,
  getUser,
  getCurrentUserId,
  getCurrentChatroom
} from "src/selectors";
import { getFilteredChatRooms, getConversationModalId } from "src/reducers";
import chatroomMetaData from "src/constants/chatroomMetaData";
import { BETA_TESTERS } from "src/constants/users";
import {
  loadChatroom as loadChatroomAction,
  loadChatroomError
} from "src/actions/chatroom";
import { rsf } from "src/db";
import * as atypes from "src/constants/actionTypes";
import { getDueDate } from "src/utils";

import type { Action, RoomId } from "src/types";
import * as storage from "src/api/storage";

localforage.setDriver(localforage.INDEXEDDB);

function* loadChatroom({ payload }: Action): any {
  try {
    const app = yield select(getAppState);
    const data = yield call(chatroom.getChatroom, payload.id);
    const {
      lastMessageAuthor,
      lastMessage: text,
      breadcrumbs,
      files,
      relatedConversations,
      checklists,
      active,
      canceled,
      groups,
      members,
      ...rest
    } = data;

    let { status } = data;

    if (status === undefined || typeof status === "object") {
      if (canceled) {
        status = -3;
      } else if (active === false) {
        status = -2;
      } else {
        status = -1;
      }
    }

    const room = {
      ...rest,
      active,
      canceled,
      status,
      createdAt: getDueDate(data.createdAt),
      updatedAt: getDueDate(data.updatedAt),
      dueDate: getDueDate(data.dueDate),
      id: `${data.id}`
    };

    const lastMessage = {
      author: lastMessageAuthor,
      text
    };

    const currentUser = yield select(getCurrentUserId);
    const currentRoom = yield select(getCurrentChatroom);
    const currentModalRoomId: ?RoomId =
      getConversationModalId(yield select(getAppState)) || "";

    if (
      lastMessageAuthor === currentUser ||
      `${currentRoom}` === `${data.id}`
    ) {
      yield put({
        type: atypes.SET_MESSAGE_COUNT,
        payload: {
          [currentRoom]: data.count
        }
      });

      yield put({
        type: atypes.HAS_NEW_MESSAGE,
        payload: {}
      });
    }

    if (room.type === "direct") {
      const user = R.head(
        R.reject(R.equals(currentUser), R.split(",", room.title || "") || [])
      );

      yield put({
        type: atypes.LOAD_DIRECT_CONVERSATIONS,
        payload: {
          [user]: room.address
        }
      });
    }

    yield put({
      type: atypes.LOAD_CHATROOM_SUCCESS,
      payload: {
        room,
        lastMessage,
        breadcrumbs: {
          [data.id]: breadcrumbs
        },
        files: {
          [data.id]: files
        },
        relatedConversations: {
          [data.id]: relatedConversations
        },
        checklists: {
          [data.id]: checklists
        },
        groups,
        members
      }
    });

    // Update the participant data of the room
    if (
      `${currentRoom}` === `${room?.id}` ||
      `${currentModalRoomId ?? ""}` === `${room?.id}`
    ) {
      yield put({
        type: atypes.SET_ROOM_PARTICIPANTS,
        payload: {
          groups,
          members
        }
      });
    }

    const isCurrentRoom = `${currentRoom}` === `${data.id}`;
    const isCurrentAuthor = lastMessageAuthor === currentUser;

    if (isCurrentAuthor || isCurrentRoom) {
      const readCount = app.chatRooms.readMessageCount.get(`${data.id}`);

      if (data.count !== readCount) {
        yield put({
          type: atypes.UPDATE_LAST_READ_REQUEST,
          payload: {
            roomId: `${data.id}`,
            uid: currentUser,
            count: data.count
          }
        });
      }
    }
  } catch (error) {
    const app = yield select(getAppState);

    // If the user is on the conversation modal, use
    // that as current room
    const currentRoom =
      app.conversationModal.roomId || (yield select(getCurrentChatroom));

    yield put({
      type: atypes.LOAD_CHATROOM_FAILURE,
      payload: {
        status: error.status,
        isCurrentRoom: `${currentRoom}` === `${payload.id}`
      }
    });
  }
}

function* watchLoadChatroom(): any {
  yield takeEvery(atypes.LOAD_CHATROOM_REQUEST, loadChatroom);
}

function* watchOpenConversationModal(): any {
  yield takeLatest(atypes.OPEN_CONVERSATION_MODAL, loadChatroom);
}

function* syncChatroomUpdates(): any {
  try {
    const orgId = yield select(getLastOrg);
    const { uid } = yield select(getUser);

    yield fork(
      rsf.firestore.syncCollection,
      `userData/${uid}/appData/settings/${orgId}/chatrooms/updates`,
      {
        successActionCreator: snap => {
          for (const { doc } of snap.docChanges()) {
            if (doc.id) {
              return loadChatroomAction(doc.id);
            }
          }
        },
        failureActionCreator: loadChatroomError
      }
    );
  } catch (error) {
    console.log("error syncing chatroom updates", error);
    yield put({
      type: atypes.CHATROOM_UPDATES_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSyncChatroomUpdates(): any {
  yield takeEvery(
    [atypes.LOAD_CHATROOMS_SUCCESS, atypes.CHATROOM_UPDATES_FAILURE],
    syncChatroomUpdates
  );
}

function* loadAllConversations(payload): any {
  try {
    const { rehydration } = yield select(getAppState);

    if (!rehydration) {
      yield take(atypes.REHYDRATION_COMPLETE);
    }

    let chatrooms = [];

    if (payload.rehydration && (payload?.chatrooms || []).length > 0) {
      chatrooms = payload.chatrooms;
    } else {
      chatrooms = yield call(chatroom.fetchAllConversations);
    }

    const orgId = yield select(getLastOrg);

    let involvedProcesses = {};

    const currentUser = yield select(getCurrentUserId);

    const chatroomData = {};
    const lastMessage = {};
    const deletedRooms = [];
    let latestUpdatedAt = null;

    for (const room of chatrooms) {
      if (room.deleted) {
        deletedRooms.push(room.id);
        continue;
      }

      lastMessage[room.id] = {
        text: room.lastMessage,
        author: room.lastMessageAuthor
      };

      if (room.updatedAt > latestUpdatedAt || !latestUpdatedAt) {
        latestUpdatedAt = moment(room.updatedAt)
          .seconds(0)
          .milliseconds(0)
          .toISOString();
      }

      chatroomData[room.id] = {
        ...R.pickAll(chatroomMetaData, room),
        autoNo: room.autoNo ? `${room.autoNo}` : null,
        id: `${room.id}`,
        createdAt: room.createdAt
          ? moment(room.createdAt).seconds(0).milliseconds(0).toISOString()
          : null,
        updatedAt: room.updatedAt
          ? moment(room.updatedAt).seconds(0).milliseconds(0).toISOString()
          : null,
        dueDate: room.dueDate ? getDueDate(room.dueDate) : null
      };
    }

    try {
      // Storing chatrooms in indexdb by org
      localforage.setItem(
        `chatrooms-${orgId}`,
        JSON.stringify(chatrooms || [])
      );

      // Set the latest chatroom's updatedAt as the
      // last fetched time
      localforage.setItem(`lastFetched-${orgId}`, latestUpdatedAt);
    } catch (error) {
      console.log(error);
    }

    yield put({
      type: atypes.LOAD_CHATROOMS_SUCCESS,
      payload: {
        chatRooms: chatroomData,
        lastMessage
      }
    });

    yield put({
      type: atypes.REMOVE_DELETED_ROOMS,
      payload: deletedRooms
    });

    const directMessages = R.mergeAll(
      R.map(
        conversation => {
          const uid = R.head(
            R.reject(
              R.equals(currentUser),
              R.split(",", conversation.title || "") || []
            )
          );

          return {
            [uid]: conversation.address
          };
        },
        R.filter(c => c.type === "direct", chatrooms)
      )
    );

    yield put({
      type: atypes.LOAD_DIRECT_CONVERSATIONS,
      payload: directMessages
    });

    yield put({
      type: atypes.START_ROOMS_CHANNEL,
      payload: {}
    });

    let membership = (yield select(getUserMembership)).toJS();

    if (!membership || (membership || []).length === 0) {
      ({ membership } = yield take(atypes.GET_USER_MEMBERSHIP_SUCCESS));
    }

    const usersConversations = R.filter(
      c => R.includes(parseInt(c.id, 10), membership || []),
      chatrooms
    );

    for (const room of usersConversations) {
      // Check if the conversation is a process and update the number
      // of times user has participated in each process type
      const { templateId } = room;
      if (templateId) {
        involvedProcesses = R.mergeWith(R.add, involvedProcesses, {
          [templateId]: 1
        });
      }
    }

    yield put({
      type: atypes.SET_INVOLVED_PROCESSES,
      payload: { ...involvedProcesses }
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: atypes.LOAD_CHATROOMS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* loadConversationsInBackground({ payload }): any {
  try {
    // Wait for 3s for the request to complete
    const { failure } = yield race({
      response: fork(loadAllConversations, payload),
      failure: delay(3000)
    });

    if (failure) {
      yield put({
        type: atypes.SET_HOME_SCREEN_REQUEST,
        payload: {}
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* loadConversations({ payload }): any {
  try {
    const currentUserId = yield select(getCurrentUserId);
    yield call(loadConversationsInBackground, { payload });

    // Start loading the rest of the chatrooms in the background
    // and load the app first (only for specific users)
    if (BETA_TESTERS.includes(currentUserId)) {
      yield call(loadConversationsInBackground, { payload });
    } else {
      yield call(loadAllConversations, payload);
    }
  } catch (err) {
    console.error(err);
  }
}

function* watchLoadAllConversations(): any {
  yield takeEvery(atypes.LOAD_ALL_CHATROOMS_REQUEST, loadConversations);
}

function* loadStoredChatrooms(): any {
  try {
    const orgId = yield select(getLastOrg);

    const lastFetched = yield call(storage.getStorage, `lastFetched-${orgId}`);
    const chatrooms = yield call(storage.getStorage, `chatrooms-${orgId}`);

    if (lastFetched && chatrooms) {
      yield put({
        type: atypes.SET_LAST_FETCHED_TIME,
        payload: {
          lastFetched
        }
      });

      yield put({
        type: atypes.LOAD_ALL_CHATROOMS_REQUEST,
        payload: {
          chatrooms: JSON.parse(chatrooms),
          rehydration: true
        }
      });
    } else {
      yield put({
        type: atypes.LOAD_ALL_CHATROOMS_REQUEST,
        payload: {}
      });
    }
  } catch (error) {
    yield put({
      type: atypes.LOAD_ALL_CHATROOMS_REQUEST,
      payload: {}
    });
  }
}

function* watchLoadStoredChatroom(): any {
  yield takeEvery(atypes.API_AUTH_SUCCESS, loadStoredChatrooms);
}

function* refetchAllconversations(): any {
  try {
    const chatrooms = yield call(chatroom.fetchAllConversations);
    yield put({
      type: atypes.REFETCH_ALL_CONVERSATIONS_SUCCESS,
      payload: R.mergeAll(chatrooms.map(c => ({ [c.id]: c })))
    });
  } catch (error) {
    yield put({
      type: atypes.REFETCH_ALL_CONVERSATIONS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchRefetchAllConversations(): any {
  yield takeEvery(
    atypes.REFETCH_ALL_CONVERSATIONS_REQUEST,
    refetchAllconversations
  );
}

function* bufferChatroomLoads(): any {
  let rooms = {};
  let interval = null;
  try {
    const roomsChannel = yield actionChannel(atypes.LOAD_CHATROOM_SUCCESS);
    interval = setInterval(() => {
      if (!R.isEmpty(rooms)) {
        roomsChannel.close();
      }
    }, 500);

    while (true) {
      const {
        payload: { room, lastMessage }
      } = yield take(roomsChannel);
      rooms = {
        chatRooms: {
          ...rooms.chatRooms,
          [room.id]: {
            ...room,
            id: `${room.id}`
          }
        },
        lastMessage: {
          ...rooms.lastMessage,
          [room.id]: lastMessage
        }
      };
    }
  } catch (error) {
    console.error("Chatroom loading failure", error);
    yield put({
      type: atypes.BATCH_LOAD_ROOMS_FAILURE,
      payload: { error: error.message }
    });
  } finally {
    clearInterval(interval);
    yield put({
      type: atypes.START_ROOMS_CHANNEL,
      payload: {}
    });
    yield put({
      type: atypes.BATCH_LOAD_ROOMS_SUCCESS,
      payload: rooms
    });
  }
}

function* watchBufferChatroomLoads(): any {
  yield takeEvery(atypes.START_ROOMS_CHANNEL, bufferChatroomLoads);
}

/**
 * Sync chatroom when user visits a chatroom
 *
 * @param {Action} action
 *
 */
function* syncChatroomOnVisit({ payload }: Action): any {
  try {
    delay(3000);
    const room = payload.id;

    if (room) {
      yield put({
        type: atypes.LOAD_CHATROOM_REQUEST,
        payload: {
          id: room
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchSyncChatroomOnVisit(): any {
  yield takeEvery(atypes.SET_CURRENT_CHATROOM_SUCCESS, syncChatroomOnVisit);
}

function* fetchChatroomUpdates(): any {
  try {
    const { lastFetched } = (yield select(getAppState)).chatRooms;

    if (!lastFetched) {
      yield put({
        type: atypes.FETCH_CHATROOM_UPDATES_ABORTED
      });
      return;
    }

    const rooms = yield call(chatroom.getChatroomUpdates, lastFetched);

    const chatRooms = {};
    const lastMessage = {};
    const ids = [];
    const deletedRooms = [];

    for (const room of rooms) {
      if (room.deleted) {
        deletedRooms.push(room.id);
        continue;
      }

      ids.push(`${room.id}`);
      lastMessage[room.id] = {
        text: room.lastMessage,
        author: room.lastMessageAuthor
      };

      chatRooms[room.id] = {
        ...R.pickAll(chatroomMetaData, room),
        id: `${room.id}`,
        createdAt: getDueDate(room.createdAt),
        updatedAt: getDueDate(room.updatedAt),
        dueDate: room.dueDate ? getDueDate(room.dueDate) : null
      };
    }

    const chatrooms = getFilteredChatRooms(yield select(getAppState));
    const latestChatroom = (yield select(getAppState)).chatRooms.byId[
      chatrooms.get(0)
    ];

    // Use the latest chatroom's updatedAt as the last fetched time.
    // this will help us rely on server time to fetch chatroom
    // updates.
    const newLastFetched = moment(latestChatroom?.updatedAt).toISOString();

    yield put({
      type: atypes.FETCH_CHATROOM_UPDATES_SUCCESS,
      payload: {
        lastFetched: newLastFetched,
        chatRooms,
        lastMessage
      }
    });

    yield put({
      type: atypes.REMOVE_DELETED_ROOMS,
      payload: deletedRooms
    });

    const oldRooms = R.values((yield select(getAppState)).chatRooms.byId);
    const orgId = yield select(getLastOrg);

    const { lastMessage: oldLastMessage } = (yield select(getAppState))
      .chatRooms;

    const {
      breadcrumbs: oldBreadcrumbs,
      files: oldFiles,
      relatedConversations: oldRelatedConversations,
      checklists: oldChecklists
    } = (yield select(getAppState)).chatRooms.attributes;

    const newRooms = rooms || [];

    for (const room of oldRooms) {
      if (!R.includes(`${room.id}`, ids)) {
        newRooms.push({
          breadcrumbs: oldBreadcrumbs[room.id],
          files: oldFiles[room.id],
          relatedConversations: oldRelatedConversations[room.id],
          checklists: oldChecklists[room.id],
          lastMessage: oldLastMessage[room.id]?.text || "",
          lastMessageAuthor: oldLastMessage[room.id]?.author || "",
          ...room
        });
      }
    }

    try {
      localforage.setItem(`lastFetched-${orgId}`, newLastFetched);
      localforage.setItem(`chatrooms-${orgId}`, JSON.stringify(newRooms || []));
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    console.error("Failed to fetch chatroom updates", error);
    yield put({
      type: atypes.FETCH_CHATROOM_UPDATES_FAILURE,
      payload: { error }
    });
  }
}

function* watchFetchChatroomUpdates(): any {
  yield takeLatest(atypes.FETCH_CHATROOM_UPDATES_REQUEST, fetchChatroomUpdates);
}

// Fetch chatroom updates once the conversations
// and user membership are available since we need
// both of it to get the latest chatroom's `updatedAt`
function* watchUserMembership(): any {
  yield takeLatest(atypes.LOAD_CHATROOMS_SUCCESS, fetchChatroomUpdates);
}

export default [
  watchOpenConversationModal(),
  watchLoadStoredChatroom(),
  watchFetchChatroomUpdates(),
  watchRefetchAllConversations(),
  watchLoadChatroom(),
  watchSyncChatroomUpdates(),
  watchLoadAllConversations(),
  watchBufferChatroomLoads(),
  watchSyncChatroomOnVisit(),
  watchUserMembership()
];
