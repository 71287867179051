// @flow

import React, { useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Text } from "@chakra-ui/react";
import usePortal from "react-useportal";

import { getChecklistFieldType, getFieldWidth } from "src/reducers";
import { Cell } from "../styles";
import { MultiContainer, MultiTable, StyledMultiField } from "./styles";
import Conversation from "./Conversation";
import User from "./User";
import Form from "./Form";
import Files from "./Files";
import Revision from "./Revision";
import LinkedField from "./LinkedField";
import { setChecklistFromManageView } from "src/actions/checklist";
import {
  checklistFieldSizeMap,
  manageViewFieldWidthMap
} from "src/constants/processInstanceColumns";

import type {
  FieldId,
  RoomId,
  WorkflowInstances,
  State,
  ColumnId
} from "src/types";
import ApprovalStatus from "./ApprovalStatus";
import Field from "./Field";
import Select from "./Select";
import EditChecklistModal from "src/components/Manage/Workflow/Instances/EditChecklistModal";

type Props = {
  selected: boolean,
  type: string,
  settings: string,
  value: any,
  index: number,
  fieldId: FieldId,
  _setChecklistFromManageView: Function,
  roomId: RoomId,
  formId?: ?number,
  hideEdit?: boolean,
  process: WorkflowInstances,
  isPrivate: boolean,
  columnId: ColumnId,
  allRevisionsShown: Boolean,
  shouldRenderEmbeddedFields?: boolean,
  embeddedIndex?: number
};

const ChecklistItem = ({
  selected,
  value,
  type,
  settings,
  _setChecklistFromManageView,
  index,
  fieldId,
  roomId,
  formId,
  hideEdit,
  process,
  isPrivate,
  columnId,
  allRevisionsShown,
  shouldRenderEmbeddedFields = false,
  embeddedIndex
}: Props) => {
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));
  const { openPortal, closePortal, isOpen, Portal } = usePortal({
    closeOnOutsideClick: false
  });
  const settingsJSON = useMemo(() => {
    try {
      return JSON.parse(settings);
    } catch (error) {
      return {};
    }
  }, [settings]);

  const openChecklistEditModal = (
    e: Event,
    index: number,
    fieldId: FieldId,
    roomId: RoomId,
    value: any,
    columnId?: string
  ) => {
    const autoNo = process?.autoNo;
    _setChecklistFromManageView({
      index,
      fieldId,
      roomId,
      columnId,
      value,
      formId,
      autoNo,
      embeddedIndex
    });
    openPortal(e);
  };

  const ChecklistCell = () => {
    switch (type) {
      case "text":
      case "number":
      case "date":
        return (
          <Field
            autoNo={process?.autoNo}
            columnId={columnId}
            selected={selected}
            value={value}
            rowIndex={index}
            openChecklistEditModal={openChecklistEditModal}
            fieldId={fieldId}
            roomId={roomId}
            hideEdit={hideEdit}
            type={type}
            embeddedIndex={embeddedIndex}
          />
        );

      case "select":
        return (
          <Select
            autoNo={process?.autoNo}
            columnId={columnId}
            selected={selected}
            value={value}
            index={index}
            openChecklistEditModal={openChecklistEditModal}
            fieldId={fieldId}
            roomId={roomId}
            hideEdit={hideEdit}
            type={type}
            multiple={settingsJSON.multiple}
            embeddedIndex={embeddedIndex}
          />
        );

      case "approval":
        return (
          <MultiContainer
            selected={selected}
            width={fieldWidth}
            borderRequired={columnId.includes("-")}
          >
            <MultiTable>
              <tbody>
                <tr>
                  <StyledMultiField
                    style={{
                      maxWidth: fieldWidth,
                      overflow: "hidden"
                    }}
                  >
                    <ApprovalStatus status={value?.status} />
                  </StyledMultiField>
                </tr>
              </tbody>
            </MultiTable>
          </MultiContainer>
        );
      case "conversation":
      case "chatPickList":
      case "workflow":
      case "task":
      case "group":
      case "childConversation":
        return (
          <Conversation
            selected={selected}
            value={value}
            multiple={settingsJSON.multiple}
            embeddedFields={settingsJSON.fields}
            openChecklistEditModal={openChecklistEditModal}
            index={index}
            fieldId={fieldId}
            roomId={roomId}
            hideEdit={hideEdit}
            settings={settingsJSON}
            autoNo={process?.autoNo}
            process={process}
            type={type}
            columnId={columnId}
          />
        );

      case "user":
        return (
          <User
            autoNo={process?.autoNo}
            columnId={columnId}
            selected={selected}
            value={value}
            multiple={settingsJSON.multiple}
            openChecklistEditModal={openChecklistEditModal}
            index={index}
            fieldId={fieldId}
            roomId={roomId}
            hideEdit={hideEdit}
            embeddedIndex={embeddedIndex}
          />
        );

      case "section":
      case "subSection":
        return <></>;

      case "form":
        return (
          <Form
            selected={selected}
            value={value}
            multiple={settingsJSON.multiple}
            openChecklistEditModal={openChecklistEditModal}
            index={index}
            fieldId={fieldId}
            roomId={roomId}
            hideEdit={hideEdit}
            autoNo={process?.autoNo}
            columnId={columnId}
            type={type}
            process={process}
          />
        );

      case "pdf":
      case "file":
        return (
          <Files
            settings={settings}
            process={process}
            columnId={columnId}
            autoNo={process?.autoNo}
            selected={selected}
            value={value}
            multiple={settingsJSON.multiple}
            openChecklistEditModal={openChecklistEditModal}
            index={index}
            fieldId={fieldId}
            roomId={roomId}
            hideEdit={hideEdit}
            type={type}
            embeddedIndex={embeddedIndex}
          />
        );

      case "revision":
        return (
          <Revision
            process={process}
            hideEdit={hideEdit}
            index={index}
            allRevisionsShown={allRevisionsShown}
            columnId={columnId}
            selected={selected}
          />
        );

      case "link":
        return (
          <LinkedField
            autoNo={process?.autoNo}
            selected={selected}
            value={value}
            settings={settingsJSON}
            openChecklistEditModal={openChecklistEditModal}
            index={index}
            fieldId={fieldId}
            roomId={roomId}
            hideEdit={hideEdit}
            columnId={columnId}
            process={process}
            embeddedIndex={embeddedIndex}
          />
        );
      default:
        return (
          <Cell
            width={fieldWidth}
            minWidth={checklistFieldSizeMap[type]}
          ></Cell>
        );
    }
  };

  if (isPrivate) {
    return (
      <Cell
        checklistItem={columnId ? true : false}
        width={manageViewFieldWidthMap[type] || "220px"}
        borderRequired={columnId && columnId.includes("-") ? true : false}
      >
        <Text textStyle="privateConversationTitle" as="i">
          Private
        </Text>
      </Cell>
    );
  }

  if (columnId && columnId.includes("-") && !shouldRenderEmbeddedFields) {
    return null;
  }

  return (
    <>
      <ChecklistCell />
      {isOpen && (
        <EditChecklistModal Portal={Portal} closePortal={closePortal} />
      )}
    </>
  );
};

ChecklistItem.defaultProps = { formId: null };

const mapStateToProps = ({ app }: State, { fieldId }) => ({
  type: getChecklistFieldType(app, fieldId)
});

export default connect(mapStateToProps, {
  _setChecklistFromManageView: setChecklistFromManageView
})(ChecklistItem);
