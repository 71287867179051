// @flow

import { connect, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import { Dock as StyledDock, Content } from "../styles";
import ChecklistSection from "../Checklist";
import LoadingState from "src/components/LoadingState";
import NoFields from "./NoFields";

import {
  RefreshWrapper,
  RefreshMessage,
  RefreshButton,
  ChecklistWrapper
} from "./styles";
import {
  getCurrentChecklist,
  getIsSrwMobile,
  getChecklistLoaderState,
  getFieldsByChecklistId
} from "src/reducers";
import { getChatroomChecklistFields } from "src/actions/checklist";

import type { AppState, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  checklists: any,
  fetchingFields: boolean,
  _getChatroomChecklistFields: Function
};

const Checklist = ({
  roomId,
  checklists,
  fetchingFields,
  _getChatroomChecklistFields
}: Props) => {
  const [refreshOption, setRefreshOption] = useState(false);
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));

  // $FlowFixMe - Optional chaining is not yet supported
  const currentChecklistId = checklists?.[0]?.id;
  const fieldsByChecklist = useSelector(({ app }) =>
    getFieldsByChecklistId(app, currentChecklistId)
  );

  const checklistFieldCount = fieldsByChecklist?.length ?? 0;

  // Show Refresh button after 3 minutes of clicking refresh
  const showRefreshButton = () => {
    setTimeout(() => {
      setRefreshOption(true);
    }, 180000);
  };

  useEffect(() => {
    // Show Refresh button after 3 minutes of initial checklist load
    showRefreshButton();
  }, []);

  // Reload checklist on clicking refresh button and hide button
  const reloadChecklist = () => {
    checklists.map(el => {
      _getChatroomChecklistFields(el.id, roomId);
    });
    showRefreshButton();
    setRefreshOption(false);
  };

  useEffect(() => {
    if (currentChecklistId) {
      _getChatroomChecklistFields(currentChecklistId, roomId);
    }
  }, [currentChecklistId]);

  return (
    <StyledDock>
      <Content>
        <ChecklistWrapper>
          {isSrwMobile && refreshOption && (
            <RefreshWrapper>
              <RefreshMessage>
                <RefreshButton onClick={reloadChecklist}>Refresh</RefreshButton>
                &nbsp;to see latest checklist data
              </RefreshMessage>
            </RefreshWrapper>
          )}

          {fetchingFields && <LoadingState type="panelBody" />}
          {!fetchingFields && checklistFieldCount === 0 && (
            <NoFields roomId={roomId} />
          )}
          {!fetchingFields && checklistFieldCount > 0 && (
            <ChecklistSection
              checklistId={currentChecklistId}
              roomId={roomId}
            />
          )}
        </ChecklistWrapper>
      </Content>
    </StyledDock>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  checklists: getCurrentChecklist(app),
  fetchingFields: getChecklistLoaderState(app)
});

export default connect(mapStateToProps, {
  _getChatroomChecklistFields: getChatroomChecklistFields
})(Checklist);
