// @flow

import React from "react";

import type { UnifizeUser } from "src/types";

import { Section, SectionTitle } from "./styles";
import Creator from "./CreatorItem";

export default function AdHocCancellers({
  cancellers
}: {
  cancellers: Array<UnifizeUser>
}) {
  if (!cancellers || cancellers?.length === 0) {
    return null;
  }
  return (
    <Section>
      <SectionTitle>Allowed Cancellers</SectionTitle>
      {cancellers.map(user => (
        <Creator
          key={user.uid}
          img={user.photoUrl}
          title={user.displayName || user.email}
        />
      ))}
    </Section>
  );
}
