// @flow

import { useSelector } from "react-redux";
import React from "react";

import SelectionSingle from "./FieldItem";
import { sortConversation } from "src/reducers";

import type { AppState, RoomId, FieldId } from "src/types";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  value: Array<number>,
  sortBy: string,
  handleRemove: Function,
  onVersionClick?: Function,
  autoLinkedRooms?: number[],
  alwaysAllowManualLinks?: boolean
};

const SelectionMultiple = ({
  roomId,
  fieldId,
  value,
  handleRemove,
  sortBy,
  onVersionClick,
  autoLinkedRooms = [],
  alwaysAllowManualLinks = false
}: Props) => {
  const sortedValues = useSelector(({ app }: { app: AppState }) =>
    sortConversation(app, sortBy, value)
  );

  if (!value || (value || []).length === 0) return null;

  return (
    <>
      {(sortedValues || []).map((conversation: number) => (
        <SelectionSingle
          key={conversation}
          roomId={roomId}
          fieldId={fieldId}
          value={conversation}
          autoLink={autoLinkedRooms.includes(conversation)}
          onVersionClick={onVersionClick}
          handleRemove={handleRemove}
          setDropdown={handleRemove}
          multiple
          alwaysAllowManualLinks={alwaysAllowManualLinks}
        />
      ))}
    </>
  );
};

export default SelectionMultiple;
