// @flow
import React from "react";

import useOverflowCheck from "src/hooks/useOverflowCheck";

import { More } from "../styles";

import type { ReactRef } from "src/types";

type Props = {
  labelRef: ReactRef
};

const ShowMore = ({ labelRef }: Props) => {
  const hasLabelOverflown = useOverflowCheck(labelRef);
  return hasLabelOverflown ? <More showMore={true}>...more</More> : null;
};

export default ShowMore;
