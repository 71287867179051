// @flow
import React, {
  createContext,
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect
} from "react";
import * as R from "ramda";
import uuid from "uuid/v4";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Measure from "react-measure";
import { useDropzone } from "react-dropzone";
import debounce from "debounce";

import SkeletonLoader from "src/components/LoadingState";
import FieldLabel from "./FieldLabel";
import FieldComponent from "./FieldComponent";
import ShowMore from "./FieldLabel/ShowMore";
import { FieldContainer, Container } from "./styles";
import { extractChecklistValue, getSettings } from "src/utils/checklist";
import { getChecklistFieldValue } from "src/actions/checklist";

import { uploadFileToChecklist } from "src/actions/file";
import {
  getChecklistFieldDetails,
  getRoomFieldValueStatus,
  getChecklistFieldBehavior,
  getChecklistFieldVisibility,
  getChecklistFormValue,
  getChecklistValue,
  getLockedStatus,
  getIsFieldFetched
} from "src/reducers";

import { dataStages } from "src/constants";
import { EMPTY_OBJECT } from "src/reducers";
import type {
  ChecklistId,
  FieldId,
  RoomId,
  ChecklistFieldTypes
} from "src/types";

interface MyContextType {
  fieldWidth: number;
}

const defaultContextValue: MyContextType = {
  fieldWidth: 0
};
const ChecklistFieldContext = createContext<MyContextType>(defaultContextValue);

type Props = {
  roomId: RoomId,
  id: FieldId,
  checklistId?: ChecklistId,
  isSectionField?: boolean,
  formId?: ?number
};

const Field = ({
  roomId,
  id,
  formId = null,
  isSectionField = false
}: Props) => {
  const dispatch = useDispatch();
  const labelRef = useRef();

  const valueStatus = useSelector(({ app }) =>
    getRoomFieldValueStatus(app, id, roomId)
  );

  const isFieldFetched = useSelector(({ app }) => getIsFieldFetched(app, id));
  const behavior =
    useSelector(({ app }) => getChecklistFieldBehavior(app, roomId, id)) ||
    EMPTY_OBJECT;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${id}`)
  );

  const roomFieldFormId = formId ? `${roomId}-${id}-${formId}` : "";

  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const checklistValue = useSelector(({ app }) =>
    getChecklistValue(app, id, roomId)
  );
  const locked = useSelector(({ app }) => getLockedStatus(app, roomId, id));

  const [fieldWidth, setFieldWidth] = useState(0);

  const isFieldHidden = details ? details.get("hidden") : false;

  const fieldVisibility = useSelector(({ app }) =>
    getChecklistFieldVisibility(app, id, roomId)
  );
  const hidden = isFieldHidden || !fieldVisibility;

  const value = extractChecklistValue(formId ? formFieldValue : checklistValue);
  const settings: any = useMemo(
    () => getSettings(details ? details.get("settings") : "{}"),
    [details]
  );

  useEffect(() => {
    if (R.isNil(checklistValue)) {
      dispatch(getChecklistFieldValue(roomId, id, true));
    }
  }, [checklistValue, isFieldFetched]);

  // $FlowFixMe
  const type: ChecklistFieldTypes = details?.get("type");

  const handleResize = useCallback(
    debounce(content => {
      setFieldWidth(content.bounds.width);
    }, 1000),
    []
  );

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      toast.error("Cannot upload multiple files to single file field.");
      return;
    }

    const storageFileName = uuid();

    dispatch(
      uploadFileToChecklist({
        fileData: settings?.multiple ? acceptedFiles : acceptedFiles[0],
        roomId: parseInt(roomId, 10),
        storageFileName,
        fieldId: id,
        value: (value || []).map(file =>
          typeof file === "object" ? file.name : file
        ),
        multiple: settings?.multiple,
        location: "checklist-upload",
        dispatch,
        formId
      })
    );
  }, []);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: settings?.multiple ? 0 : 1
  });

  const dragAndDropProps =
    type === "file" && !locked ? getRootProps() : EMPTY_OBJECT;

  if (type !== "section" && R.isNil(valueStatus)) return null;

  if (type !== "section" && valueStatus <= dataStages.fetching)
    return <SkeletonLoader type="checklistField" />;

  if (behavior?.current === "hidden") return null;

  return (
    !hidden &&
    (type === "section" ? null : (
      <ChecklistFieldContext.Provider value={{ fieldWidth }}>
        <Measure bounds onResize={handleResize}>
          {({ measureRef }) => (
            <FieldContainer ref={measureRef} isSectionField={isSectionField}>
              <FieldLabel labelRef={labelRef} roomId={roomId} fieldId={id} />
              <ShowMore labelRef={labelRef} />
              <Container
                {...dragAndDropProps}
                isDragActive={isDragActive}
                isSectionField={isSectionField}
              >
                <FieldComponent roomId={roomId} formId={formId} fieldId={id} />
              </Container>
            </FieldContainer>
          )}
        </Measure>
      </ChecklistFieldContext.Provider>
    ))
  );
};

export default Field;

export { ChecklistFieldContext };
