// @flow

import transit from "transit-js";
import * as R from "ramda";

import { formatChecklistFieldValue } from "src/formatChecklistFieldValue";
import fieldReader from "src/transit/checklist/field/reader";
import type {
  ChecklistValue,
  RoomId,
  LinkedFieldValue,
  UnifizeChatRoom,
  LinkedField,
  LinkedFieldSettings,
  ApprovalFieldSettingsV2,
  ApprovalFieldValue,
  FormFieldValue,
  FieldId,
  ColumnId,
  WorkflowInstances,
  FieldsById,
  UID,
  UsersAndGroups,
  UserFieldValue,
  GroupId,
  ChecklistFieldTypes,
  ChecklistField
} from "src/types";
import { generateById } from "./index";
import { omitedFields } from "src/constants/processInstanceColumns";
import { conversationFieldTypes } from "src/constants";
import { sortBySeqNo, getDueDate } from "src/utils";
import { isValidChatroom } from "src/utils/chatroom";
import * as morpheus from "src/utils/morpheus";

/**
 * Parse and return JSON settings as an object
 */
export const getSettings = (settings: string) => {
  try {
    return JSON.parse(settings);
  } catch (e) {
    console.error(e);
    return {};
  }
};

/**
 * Extract the value property inside the Checklist Value Object
 */
export const extractChecklistValue = (checklistValue: ?ChecklistValue) =>
  ((checklistValue || {}).val || {}).value;

/**
 * Extract the placeholder value from settings
 */
export const getPlaceholder = (settings: any, defaultText: string): string =>
  settings && settings.placeholder ? settings.placeholder : defaultText;

/**
 * Get Label from transit reader
 */
export const getLabel = (type: string): ?string => {
  try {
    const reader = transit.reader("json", { handlers: fieldReader });
    const { label } = reader.read(type);
    return label;
  } catch (error) {
    return "";
  }
};

export const getChatroomMetaData = (chatroom: UnifizeChatRoom) => ({
  id: `${chatroom.id}`,
  owner: chatroom.owner,
  seqNo: chatroom.seqNo,
  autoNo: chatroom.autoNo,
  versionComment: chatroom.versionComment,
  versionCount: chatroom.versionCount,
  currentVersion: chatroom.currentVersion,
  version: chatroom.version,
  status: chatroom.status,
  privacy: chatroom.privacy,
  active: chatroom.active,
  canceled: chatroom.canceled,
  templateId: chatroom.templateId,
  title: chatroom.title,
  address: chatroom.address,
  type: chatroom.type,
  outcome: !R.isNil(chatroom.outcome) ? chatroom.outcome : null,
  dueDate: getDueDate(chatroom.dueDate),
  archived: !R.isNil(chatroom.archived) ? chatroom.archived : null
});

export const getEmbeddedFields = (embeddedConversations: Array<Object>) => {
  let embedded = {};
  let nestedFieldDetails = [];
  const chatroomMetaData = [];

  const reader = transit.reader("json", { handlers: fieldReader });

  for (let conversation of embeddedConversations) {
    conversation = conversation.chatroom || conversation;
    embedded[`${conversation.id}`] = [];

    if (conversation.fields && R.type(conversation.fields) === "Array") {
      for (const field of conversation.fields || []) {
        const checklistFieldType = reader.read(field.type);

        nestedFieldDetails.push({
          id: field.fieldId,
          type: checklistFieldType
        });

        embedded[`${conversation.id}`].push(formatChecklistFieldValue(field));
      }

      // Only if the chatroom data from API is valid, add it to the
      // list
      if (isValidChatroom(conversation)) {
        chatroomMetaData.push(getChatroomMetaData(conversation));
      }
    }
  }

  return { embedded, nestedFieldDetails, chatroomMetaData };
};

const compareFields = (fieldA, fieldB) => fieldA.id === fieldB.id;

export const mergeNewFields = (
  fields: Array<Object>,
  options: Array<Object>,
  report?: boolean
) => {
  const fieldsById = generateById({ items: fields });
  const updatedOptions = options.reduce((accumulator, option) => {
    // Update field settings & field label if it's changed
    if (option.label && fieldsById[option.id] && fieldsById[option.id]?.label) {
      return [
        ...accumulator,
        {
          ...option,
          label: fieldsById[option.id].label,
          settings: fieldsById[option.id].settings
        }
      ];
    }
    // Remove if field is deleted
    else if (option.label && !fieldsById[option.id]) {
      return accumulator;
    }

    return [...accumulator, option];
  }, []);

  // Merge newly created fields
  return [
    ...updatedOptions,
    ...R.differenceWith(compareFields, fields, options)
      .filter(f => f.label && !R.includes(f.type, omitedFields))
      .map(field => ({ ...field, key: field.id, active: !report }))
  ];
};

export const getMinApprovers = (
  settings: ApprovalFieldSettingsV2,
  value: ApprovalFieldValue
): number => {
  if (settings.requiredApprovers === "some") {
    return settings.minApprovers || 1;
  }

  if (!value.config) return 1;

  return value.config.minApprovers || 1;
};

export const getFormData = ({
  value,
  roomId
}: {
  value: Object,
  roomId: RoomId
}) => {
  let fields = [];
  const fieldSeqNosById = {};
  let fieldsByForm = {};
  let fieldValues = {};
  let formTemplates = {};
  let sectionIndex = null;

  const reader = transit.reader("json", { handlers: fieldReader });

  let embedded = {};
  let nestedFieldDetails = [];

  for (const val of value.value) {
    sectionIndex = null;
    fieldsByForm[val.id] = [];
    formTemplates[val.id] = {
      templateId: val.templateId,
      templateTitle: val.templateTitle,
      address: val.address,
      color: val?.templateSettings?.color
    };

    for (const field of sortBySeqNo(val.fields)) {
      const fieldType = reader.read(field.type);

      // Handle subsections
      if (fieldType.type === "subSection") {
        sectionIndex = fieldsByForm[val.id].length;
        fieldsByForm[val.id].push({ sectionId: field.fieldId, fields: [] });
      } else if (
        typeof sectionIndex === "number" &&
        fieldsByForm[val.id][sectionIndex]
      ) {
        fieldsByForm[val.id][sectionIndex].fields.push(field.fieldId);
      } else {
        fieldsByForm[val.id].push(field.fieldId);
      }

      fieldValues[`${roomId}-${field.fieldId}-${val.id}`] =
        formatChecklistFieldValue(field);

      fields.push({
        id: field.fieldId,
        seqNo: field.seqNo,
        ...fieldType
      });

      fieldSeqNosById[field.fieldId] = field.seqNo;

      try {
        // Handling embeded fields of embedded values
        if (
          conversationFieldTypes.includes(fieldType) &&
          field?.value &&
          R.type(field.value || []) === "Array"
        ) {
          const embeddedData = getEmbeddedFields(field.value);

          embedded = R.mergeDeepWith(
            R.pipe(R.concat, R.uniq),
            embedded,
            embeddedData.embedded
          );

          nestedFieldDetails = R.concat(
            nestedFieldDetails,
            embeddedData.nestedFieldDetails
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  // $FlowFixMe
  nestedFieldDetails = R.uniq(nestedFieldDetails);

  Object.keys(fieldsByForm).forEach(formId => {
    let formFields = R.clone(fieldsByForm[formId]);

    // sort first level fields(field and subsection)
    const sortFirst = (a, b) => {
      const id1 = String(typeof a === "number" ? a : a.sectionId);
      const id2 = String(typeof b === "number" ? b : b.sectionId);

      return fieldSeqNosById[id1] - fieldSeqNosById[id2];
    };
    formFields = R.sort(sortFirst, formFields);

    // sort fields inside subsection
    formFields = formFields.map(item => {
      if (typeof item === "number") {
        return item;
      }

      const sortedFields = R.sortBy(id => fieldSeqNosById[String(id)])(
        item.fields
      );
      return {
        sectionId: item.sectionId,
        fields: sortedFields
      };
    });

    fieldsByForm[formId] = formFields;
  });

  return {
    fields,
    fieldValues,
    fieldsByForm,
    formTemplates,
    embedded,
    nestedFieldDetails
  };
};

export const formFieldDeleteHandler = ({
  currentValue,
  payload
}: {
  currentValue: FormFieldValue,
  payload: {
    extraBody: null,
    formId: ?number,
    httpMethod: "DELETE",
    id: number,
    progress: boolean,
    roomId: string,
    value: {
      checked: boolean,
      type: "form",
      value: number
    }
  }
}) => {
  return R.mergeDeepRight(currentValue, {
    val: {
      value: R.reject(R.equals(payload.value.value))(currentValue.val.value)
    },
    value: R.reject(R.propEq("id", payload.value.value))(currentValue.value)
  });
};

export const userFieldDeleteHandler = ({
  currentValue,
  payload
}: {
  currentValue: UserFieldValue,
  payload: {
    extraBody: null,
    formId: null,
    httpMethod: "DELETE",
    id: FieldId,
    progress: boolean,
    roomId: RoomId,
    value: {
      checked: boolean,
      type: string,
      value: {
        type: string,
        uid?: UID,
        id?: GroupId
      }
    }
  }
}) => {
  return R.mergeDeepRight(currentValue, {
    val: {
      value:
        payload.value.value.type === "user"
          ? R.reject(R.equals(payload.value.value.uid))(currentValue.val.value)
          : R.reject(R.equals(payload.value.value.id))(currentValue.val.value)
    },
    value:
      payload.value.value.type === "user"
        ? R.reject(R.propEq("uid", payload.value.value.uid))(currentValue.value)
        : R.reject(R.propEq("id", payload.value.value.id))(currentValue.value)
  });
};

export const linkedFieldDeleteHandler = ({
  item,
  payload
}: {
  item: {
    fieldId: number,
    val: {
      type: "link",
      value: number[]
    },
    value: LinkedFieldValue
  },
  payload: {
    extraBody: null,
    formId: ?number,
    httpMethod: "DELETE",
    id: number,
    progress: boolean,
    roomId: string,
    value: {
      checked: boolean,
      type: "link",
      value: { originChatroomId: number }
    }
  }
}) => {
  const chatroomIdToDelete = payload.value.value.originChatroomId;
  const newValue = R.clone(item.value);

  if (newValue.entities.chatrooms)
    delete newValue.entities.chatrooms[chatroomIdToDelete];

  newValue.result = R.reject(
    R.equals(chatroomIdToDelete),
    R.clone(newValue.result)
  );

  return {
    ...item,
    value: newValue,
    val: {
      ...item.val,
      value: newValue
    }
  };
};

export const getLinkedFieldSettings = ({
  details,
  templateId
}: {
  details: LinkedField,
  templateId: number
}): LinkedFieldSettings => {
  const sourceProcessSettings = details.get("sourceProcessSettings").toJS();
  const linkedProcessSettings = details.get("linkedProcessSettings").toJS();

  const settings =
    sourceProcessSettings.workflow !== templateId
      ? sourceProcessSettings
      : linkedProcessSettings;

  return settings;
};

export const getAllContingentApprovals = ({
  fieldId,
  fieldsById
}: {
  fieldId: FieldId,
  fieldsById: FieldsById
}): FieldId[] => {
  const field = fieldsById.get(String(fieldId));

  const settings = morpheus.approval(JSON.parse(field.get("settings")));

  const { contingentApprovals = [] } = settings;

  let allContingentApprovals = [...contingentApprovals];

  contingentApprovals.forEach(fieldId => {
    allContingentApprovals = allContingentApprovals.concat(
      getAllContingentApprovals({
        fieldId,
        fieldsById
      })
    );
  });

  return R.uniq(allContingentApprovals);
};

/**
 * Checks if an embedded field has a parent linked field
 *
 * @param {WorkflowInstances} instance containing the linked field
 * @param {columnId} columnId of the linked field
 * @returns {boolean} whether the linked field exists
 */
export const isLinkedFieldExists = ({
  instance,
  columnId
}: {
  instance: WorkflowInstances,
  columnId: ColumnId
}) => {
  if (!instance) {
    return false;
  }
  const linkedFieldId = R.init(columnId.split("-")).join();
  return instance[linkedFieldId]?.result?.length !== 0;
};

/**
 * Accepts a list of user checklist values and return only latest
 * notifications
 *
 * @param {array} newValue - list of new values
 * @param {array} oldValue - list of old values
 * @returns {array} Array of filtered latest values
 */
export const getFilteredUserNotification = (
  newValue: Array<UsersAndGroups>,
  oldValue: Array<UsersAndGroups>
) => {
  const newUsers = newValue.filter(item => item.type === "user");
  const oldUserIds = oldValue
    .filter(item => item.type === "user")
    .map(item => {
      if (item.type === "user") {
        return item.uid;
      }
    });
  const filteredUsers = newUsers.filter(item => !oldUserIds.includes(item.uid));

  const newGroups = newValue.filter(item => item.type === "group");
  const oldGroupIds = oldValue
    .filter(item => item.type === "group")
    .map(item => {
      if (item.type === "group") {
        return item.id;
      }
    });
  const filteredGroups = newGroups.filter(
    item => !oldGroupIds.includes(item.id)
  );

  return [...filteredUsers, ...filteredGroups];
};

/**
 * Converts string user value from previous api to objects.
 * Used to standardize the structure of user checklist notification
 * before rendering.
 *
 * @param {object | string} value - string or object value from api
 * @returns {object} transformed object value
 */
export const transformUserNotification = (value: UsersAndGroups | UID) => {
  if (typeof value === "string") {
    return { uid: value, type: "user" };
  } else {
    return value;
  }
};

/**
 * Extract the field label from field details
 * @param {number} templateId - process template ID of the process the
 * chatroom belongs to
 * @param {ChecklistField} details - field details
 * @param {ChecklistFieldTypes} type - field type
 * @return {string} label - the field label
 */
export const getFieldLabel = ({
  templateId,
  details,
  type
}: {
  templateId: ?number,
  details: ?ChecklistField,
  type: ChecklistFieldTypes
}): string => {
  let label = details ? details.get("label") : "";

  if (details && type === "link" && templateId) {
    // $FlowFixMe
    const settings = getLinkedFieldSettings({ templateId, details });
    label = settings.label;
  }

  return label;
};
