import styled from "styled-components";
import * as colors from "src/styles/constants/colors";

export const StyledNumberInput = styled.input`
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  width: 3rem;
  border-radius: 2px;
  outline: none;
  padding: 4px;
  line-height: 24px;
  font-size: 0.88rem;
  flex: 1;
  border: 1px solid ${colors.grey35};
`;
