// @flow

import * as R from "ramda";
import { isMobile } from "react-device-detect";
import {
  call,
  put,
  takeEvery,
  take,
  takeLatest,
  select,
  all
} from "redux-saga/effects";
import * as atypes from "src/constants/actionTypes";
import { BETA_TESTERS } from "src/constants/users";
import defaultFilter from "src/constants/defaultFilter";
import getAppState, {
  getUser,
  getRequestedChatRoom,
  getLastRead,
  getCurrentUserId,
  getLastOrg
} from "src/selectors";
import * as chatroom from "src/api/chatroom";
import {
  getSignInError,
  getChatRoomsById,
  getOrgName,
  getCurrentChatRoom,
  getIsSrwMobile,
  getChatroomTitle,
  getChatRoom,
  getRole,
  getUserMembership
} from "src/reducers";

import type { Action, UnifizeChatRoomById, UnifizeChatRoom } from "src/types";

const sortByTime = R.sortBy(x => new Date(x.time).getTime());

function* setCurrentChatroom({ meta, payload }): any {
  try {
    const { query } = meta || {};
    const { authCode } = query || {};
    const { id: address } = payload;
    const templateId = ((meta || {}).query || {}).templateId;

    // Clear the pendingMembers from store when switching chatrooms
    yield put({
      type: atypes.CLEAR_PENDING_MEMBERS
    });

    if (authCode) {
      yield put({
        type: atypes.SINGLE_RESPONSE_WINDOW,
        payload: {
          ...query,
          orgId: parseInt(query.orgId, 10),
          address
        }
      });

      yield put({
        type: atypes.SHOW_PARTICIPANT_LOADER,
        payload: {
          address
        }
      });

      yield take(atypes.SRW_SERVER_AUTH_SUCCESS);
      yield take(atypes.SRW_CURRENT_CHATROOM);
      yield take(atypes.SRW_CHATROOM_ATTRIBUTES);

      const app = yield select(getAppState);
      const room = getCurrentChatRoom(app);
      const isMobile = getIsSrwMobile(app);

      if (isMobile && room.type === "workflow") {
        yield put({
          type: atypes.SHOW_SRW_DOCK,
          payload: {
            dockContent: "checklist"
          }
        });
      }
    } else {
      const { uid } = yield select(getUser);
      const serverError = getSignInError("sendAuthToken")(
        yield select(getAppState)
      );

      if (serverError) {
        yield put({ type: atypes.SIGN_IN });
      } else if (!uid) {
        // If user is not signed in store the chatroom request from the url
        // and use it while after LOAD_CHATROOMS_SUCCESS
        yield put({ type: atypes.SIGN_IN });

        const source = ((meta || {}).query || {}).source;

        yield put({
          type: atypes.SET_REQUESTED_CHATROOM,
          payload: { id: payload.id, source: source || null, templateId }
        });

        // Get orgId is from requested chatroom's document
        // and set it as the current org for the session
        if (!isNaN(payload.id) && payload.id) {
          const chatroomDetails = yield call(chatroom.getChatroom, payload.id);
          const { orgId } = chatroomDetails;

          // Set lastOrg with orgId
          // eslint-disable-next-line no-undef
          sessionStorage.setItem("lastOrg", orgId);
        }
      } else {
        const chatrooms: UnifizeChatRoomById = (yield select(getAppState))
          .chatRooms.byId;

        if (chatrooms) {
          let currentChatroom: string | null = null;
          let currentTemplateId = null;
          R.map((room: UnifizeChatRoom) => {
            if (room.address === payload.id) {
              currentChatroom = room.id;
              currentTemplateId = room.templateId;
            }
            return null;
          }, chatrooms);

          if (currentChatroom) {
            // Show participant loader when switching chatrooms
            yield put({
              type: atypes.SHOW_PARTICIPANT_LOADER,
              payload: {
                address: chatrooms[currentChatroom].address
              }
            });
            // Covnersation already loaded
            yield put({
              type: atypes.UPDATE_LAST_READ_REQUEST,
              payload: {
                roomId: `${currentChatroom}`,
                uid,
                count: chatrooms[currentChatroom].count
              }
            });

            yield put({
              type: atypes.SET_CURRENT_CHATROOM_SUCCESS,
              payload: {
                address: chatrooms[currentChatroom].address,
                id: currentChatroom,
                location:
                  (payload.source || "").split('"').join("") === "summary"
                    ? "daily-email-summary"
                    : null,
                templateId: currentTemplateId
              },
              meta: {
                query: {
                  templateId: currentTemplateId
                }
              }
            });

            // get checklist details for header
            yield put({
              type: atypes.GET_CHECKLIST_REQUEST,
              payload: { workflow: currentChatroom }
            });

            try {
              const titleMeta: any = document.getElementById("title-meta");
              const descMeta: any = document.getElementById("description-meta");

              if (titleMeta) {
                titleMeta.content = chatrooms[currentChatroom].title;
              }

              if (descMeta) {
                descMeta.content = chatrooms[currentChatroom].title;
              }
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          yield put({
            type: atypes.UPDATE_CURRENT_CHATROOM,
            payload
          });
        }
      }
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.SET_CURRENT_CHATROOM_FAILURE,
      payload: error
    });
  }
}

function* setDefaultChatroom(): any {
  try {
    if (isMobile) {
      yield put({ type: atypes.SIGN_IN, payload: { page: "mobile" } });
    } else {
      let { homeScreenEnabled } = (yield select(getAppState)).orgs;

      if (homeScreenEnabled === null) {
        yield take(atypes.SET_HOME_SCREEN_STATUS);

        homeScreenEnabled = (yield select(getAppState)).orgs.homeScreenEnabled;
      }

      const requestedChatRoom: string = (yield select(getRequestedChatRoom)).id;
      const { source, templateId } = yield select(getRequestedChatRoom);

      let chatroomsById: UnifizeChatRoomById = getChatRoomsById(
        yield select(getAppState)
      );
      const currentUserId = yield select(getCurrentUserId);

      // Prioritise loading the app for specific users
      const shouldLoadAppInstantly = BETA_TESTERS.includes(currentUserId);

      if (R.keys(chatroomsById).length === 0 && !shouldLoadAppInstantly) {
        yield take(atypes.LOAD_CHATROOMS_SUCCESS);
      }

      chatroomsById = getChatRoomsById(yield select(getAppState));

      const address = R.map(R.prop("address"), R.values(chatroomsById));

      const isRequestedChatRoom =
        requestedChatRoom &&
        isNaN(requestedChatRoom) &&
        R.includes(requestedChatRoom, address);

      // Switch to requested chatroom if it is there
      if (isRequestedChatRoom && !shouldLoadAppInstantly) {
        yield put({
          type: atypes.SET_CURRENT_CHATROOM_REQUEST,
          payload: {
            id: requestedChatRoom,
            source
          },
          meta: {
            query: {
              templateId:
                templateId || chatroomsById[requestedChatRoom]?.templateId
            }
          }
        });

        // Show participant loader when the page reloads and user is
        // taken to the my conversations page
        yield put({
          type: atypes.SHOW_PARTICIPANT_LOADER,
          payload: {
            address: chatroomsById[requestedChatRoom].address
          }
        });
      } else if (homeScreenEnabled) {
        yield put({
          type: atypes.SET_HOME_SCREEN_REQUEST,
          payload: {}
        });
      } else {
        yield put({
          type: atypes.SET_WATCH_SET_PREVIOUS_CHATROOM,
          payload: {}
        });
      }
    }
  } catch (error) {
    console.error("Set Default Chatroom Error: ", error);
  }
}

function* watchSetDefaultChatroom(): any {
  yield takeLatest(atypes.LOAD_DEFAULT_CHATROOM, setDefaultChatroom);
}

function* loadGeneralChatroom({ meta }: Action): any {
  try {
    const chatroomsById = getChatRoomsById(yield select(getAppState));
    const memberships = getUserMembership(yield select(getAppState));
    const chatrooms = R.values(chatroomsById);

    if (chatrooms.length > 0) {
      const membershipChatrooms =
        R.filter(({ id }) => memberships.includes(Number(id)), chatrooms) || [];
      const generalChatrooms = R.filter(
        chatroom => (chatroom.meta || {}).systemTitle === "general",
        membershipChatrooms
      );

      if (generalChatrooms.length > 0) {
        yield put({
          type: atypes.SET_CURRENT_CHATROOM_REQUEST,
          payload: {
            id: R.head(generalChatrooms).address
          },
          meta
        });
      } else {
        yield put({
          type: atypes.SET_CURRENT_CHATROOM_REQUEST,
          payload: {
            id: R.head(membershipChatrooms).address
          },
          meta
        });
      }
    } else {
      yield put({
        type: atypes.SET_CURRENT_CHATROOM_SUCCESS,
        payload: {
          id: 1
        },
        meta
      });
    }
  } catch (error) {
    console.log("Error loading general conversation", error);
  }
}

function* watchLoadGeneralChatroom(): any {
  yield takeEvery(atypes.LOAD_GENERAL_CHATROOM, loadGeneralChatroom);
}

function* watchSetCurrentChatroom(): any {
  yield takeEvery(atypes.SET_CURRENT_CHATROOM_REQUEST, setCurrentChatroom);
}

function* setCurrentChatroomAlias({ payload }: any) {
  yield put({
    type: atypes.SET_CURRENT_CHATROOM_REQUEST,
    payload
  });
}

function* watchSetCurrentChatroomAlias(): any {
  yield takeEvery(atypes.SET_CURRENT_CHATROOM_ALIAS, setCurrentChatroomAlias);
}

function* filterView({ meta }): any {
  try {
    const { query } = meta || {};
    const {
      status,
      ownedByMe,
      critical,
      new: newConvo,
      workflow,
      unread,
      source,
      templateId
    } = query || {};

    console.log("templateId", templateId);

    yield put({
      type: atypes.HOME,
      payload: {}
    });

    yield all([
      take(atypes.GET_DEPARTMENTS_SUCCESS),
      take(atypes.GET_CURRENT_ORG_DETAILS_SUCCESS)
    ]);

    // filter out overdue & pending conversations
    if (status === "overdue") {
      const workflowName = getChatroomTitle(
        yield select(getAppState),
        workflow
      );
      yield put({
        type: atypes.SET_QUERY_FILTER,
        payload: {
          filter: defaultFilter.overdue,
          name: workflowName
        }
      });
      yield put({
        type: atypes.SET_QUERY_ACTIVE,
        payload: { active: "pending" }
      });
    }

    // filter based on status
    if (status && status !== "overdue")
      yield put({
        type: atypes.SET_QUERY_ACTIVE,
        payload: { active: status }
      });

    // filter out conversations owned by me
    if (ownedByMe) {
      const owner = yield select(getCurrentUserId);
      yield put({
        type: atypes.SET_OWNER,
        payload: { owner }
      });
    }

    // filter new conversations
    if (newConvo)
      yield put({
        type: atypes.SET_QUERY_FILTER,
        payload: { filter: defaultFilter.newConvo }
      });

    // filter out unread conversations
    if (unread)
      yield put({
        type: atypes.SET_UNREAD,
        payload: { unread: true }
      });

    // filter out critical conversations
    if (critical) {
      yield put({
        type: atypes.SET_QUERY_FILTER,
        payload: { filter: defaultFilter.critical }
      });
      yield put({
        type: atypes.SET_QUERY_ACTIVE,
        payload: { active: "pending" }
      });
    }

    // filter based on workflow process

    if (workflow && !isNaN(Number(workflow))) {
      const workflowName = getChatroomTitle(
        yield select(getAppState),
        workflow
      );
      yield put({
        type: atypes.SET_WORKFLOW_FILTER,
        payload: { workflow: parseInt(workflow, 10), name: workflowName }
      });
    }

    const orgId = yield select(getLastOrg);
    const orgName = getOrgName(yield select(getAppState), orgId);

    yield put({
      type: atypes.NAVIGATE_FROM_DAILY_SUMMARY,
      payload: {
        location: source === "summary" ? "daily-email-summary" : null,
        orgId,
        orgName,
        ...R.omit(["templateId"], query || {})
      }
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchForFilterView(): any {
  yield takeLatest(atypes.SET_FILTER_VIEW, filterView);
}

function* changeCurrentChatroom({ payload }: Action): any {
  try {
    const chatrooms = (yield select(getAppState)).chatRooms.byId;
    yield put({
      type: atypes.SET_CURRENT_CHATROOM_REQUEST,
      payload: { id: chatrooms[payload.id].address }
    });
  } catch (err) {
    yield put({
      type: atypes.SET_CURRENT_CHATROOM_FAILURE,
      payload: err
    });
  }
}

function* watchChangeCurrentChatroom(): any {
  yield takeEvery(atypes.CHANGE_CURRENT_CHATROOM, changeCurrentChatroom);
}

function* initiateCurrentChatroom({ payload }: Action): any {
  try {
    const { isSingleResponse } = (yield select(getAppState)).srw;
    if (isSingleResponse)
      yield put({
        type: atypes.SRW_SHOW_SIGNUP_MODAL,
        payload: {}
      });
    else {
      yield put({
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload
      });

      // Show participant loader when a chatroom is opened from
      // within another chatroom - through notifications/messages
      yield put({
        type: atypes.SHOW_PARTICIPANT_LOADER,
        payload: {
          address: payload.id
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.INITIATE_CURRENT_CHATROOM_ERROR,
      payload: { error }
    });
  }
}

function* watchInitiateCurrentChatroom(): any {
  yield takeEvery(
    atypes.INITIATE_CURRENT_CHATROOM_SET,
    initiateCurrentChatroom
  );
}

function* setPreviousChatroom({ payload }: Action): any {
  try {
    const requestedChatRoom = payload.id;
    const { source } = yield select(getRequestedChatRoom);

    let chatroomsById = getChatRoomsById(yield select(getAppState));
    const recentChatrooms = (yield select(getLastRead)).toJS();

    const { id: lastRead } =
      R.head(
        R.reverse(
          sortByTime(
            R.map(
              c => ({ time: recentChatrooms[c], id: c }),
              R.keys(recentChatrooms)
            )
          )
        ) || []
      ) || {};

    if (R.keys(chatroomsById).length === 0) {
      yield take(atypes.LOAD_CHATROOMS_SUCCESS);
      chatroomsById = getChatRoomsById(yield select(getAppState));
    }

    const address = R.map(R.prop("address"), R.values(chatroomsById));

    // Switch to requested chatroom if it is there
    if (
      requestedChatRoom &&
      isNaN(requestedChatRoom) &&
      R.includes(requestedChatRoom, address)
    ) {
      yield put({
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: {
          id: requestedChatRoom,
          source
        },
        meta: {
          query: {
            templateId: chatroomsById[requestedChatRoom].templateId
          }
        }
      });
    }
    // Switch to last visited conversation if user has been in the platform before
    else if (lastRead && (chatroomsById[lastRead] || {}).address) {
      yield put({
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: {
          id: chatroomsById[lastRead].address,
          source
        },
        meta: {
          query: {
            templateId: chatroomsById[lastRead].templateId
          }
        }
      });
    }
    // Switch to general if user login for the first time
    else {
      yield put({ type: atypes.LOAD_GENERAL_CHATROOM });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Set Default Chatroom Error: ", error);
  }
}

function* watchSetPreviousChatroom(): any {
  yield takeLatest(atypes.SET_WATCH_SET_PREVIOUS_CHATROOM, setPreviousChatroom);
}

function* openRightPanel({ payload }: Action): any {
  try {
    if (payload.id) {
      const currentUser = yield select(getCurrentUserId);
      const role = getRole(yield select(getAppState), currentUser);
      const room = getChatRoom(yield select(getAppState), payload.id);

      if (role === "demo" && room.type === "workflow") {
        yield put({
          type: atypes.SHOW_DOCK,
          payload: {
            dockContent: "checklist"
          }
        });
      }
    }
  } catch (error) {}
}

function* watchOpenRightPanel(): any {
  yield takeLatest(atypes.SET_CURRENT_CHATROOM_SUCCESS, openRightPanel);
}

export default [
  watchOpenRightPanel(),
  watchSetPreviousChatroom(),
  watchSetCurrentChatroom(),
  watchSetDefaultChatroom(),
  watchLoadGeneralChatroom(),
  watchSetCurrentChatroomAlias(),
  watchForFilterView(),
  watchChangeCurrentChatroom(),
  watchInitiateCurrentChatroom()
];
