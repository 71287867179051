// @flow

import React, { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import {
  getChatroomType,
  getChatroomPrivacy,
  getChatroomTitle,
  getChecklistValue,
  getChecklistFieldDetails,
  getLockedStatus
} from "src/reducers";
import useChatroom from "src/hooks/useChatroom";

import useBoolean from "src/hooks/useBoolean";

import { HStack, Text, VStack, Box } from "@chakra-ui/react";
import Icon from "src/icons";
import useFields from "../useFields";
import WithoutProcessTitle from "src/components/chatroom/Name/WithoutProcessTitle";
import Status from "src/components/chatroom/Status";
import DueDate from "src/components/chatroom/DueDate";
import RevisionButton from "src/components/Dock/Checklist/Revision/RevisionButton";
import NestedFields from "./NestedFields";
import { ChecklistFieldContext } from "src/components/Dock/Checklist/Field";

import * as colors from "src/styles/constants/colors";
import * as styles from "./FieldItem.styles";
import { WideMetaData as MetaData } from "./styles";
import Owner from "src/containers/chatroom/Owner";
import { getChipWidth } from "./utils";
import Modal from "./Modal";

import type { Privacy, RoomId, FieldId } from "src/types";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  value: number,
  multiple: ?boolean,
  handleRemove: Function,
  setDropdown: Function,
  onVersionClick?: Function,
  autoLink?: boolean,
  alwaysAllowManualLinks?: boolean
};

const ConversationFieldItem = ({
  roomId,
  fieldId,
  value,
  multiple,
  autoLink,
  handleRemove,
  setDropdown,
  onVersionClick,
  alwaysAllowManualLinks = false
}: Props) => {
  const checklistValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const disabled = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const chatroomDetails = useChatroom(String(value));
  const versionCount = chatroomDetails?.versionCount;
  const privacy: Privacy =
    useSelector(({ app }) => getChatroomPrivacy(app, `${value}`)) || "none";
  const title =
    useSelector(({ app }) => getChatroomTitle(app, `${value}`)) || "";
  const type =
    useSelector(({ app }) => getChatroomType(app, `${value}`)) || null;

  const { settings } = useFields({
    checklistValue,
    details
  });

  const showMetaData = (settings || {}).showMetaData || false;
  const showFieldNames = (settings || {}).showFieldNames || false;
  const disableTitleHyperlink = R.has("disableTitleHyperlink", settings || {})
    ? settings.disableTitleHyperlink
    : false;
  const fields = (settings || {}).fields || [];

  // Boolean to determine whether embedded fields are shown or not
  const { value: expand, toggleBoolean: toggleExpand } = useBoolean(false);
  const { value: isModalOpen, toggleBoolean: setIsModalOpen } =
    useBoolean(false);
  const { fieldWidth } = useContext(ChecklistFieldContext);

  // Remove current conversation from field value
  const removeConversation = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsModalOpen(true);
    },
    [value, handleRemove]
  );

  // Edit field value
  const handleEdit = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setDropdown(true);
    },
    [setDropdown]
  );

  // Toggle visibility of embedded fields
  const toggleFields = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      toggleExpand();
    },
    [toggleExpand]
  );

  // When a new room is created then `versionCount` will be missing
  // from store until the room is synced. Show a loader for this state

  // NOTE: For some reason, the loader was being triggered, for already existing fields
  // if (type !== "group" && R.isNil(versionCount) && privacy === "none") {
  //   return <LoadingState type="chatblock" hideMetaData={!showMetaData} />;
  // }

  // If privacy is null OR it is "full" and type is null
  // then the conversation is considered to be private
  const isConversationPrivate = !privacy || (privacy === "full" && !type);

  // If field type is multi then the action is remove
  // For single field type the action is edit
  // If embedded fields are shown then toggle visibility
  const titleRowActionIcon = expand
    ? "caretUp"
    : multiple
      ? "decrement"
      : "checklistEdit";
  const titleRowAction = expand
    ? toggleFields
    : multiple
      ? removeConversation
      : handleEdit;

  // For linked field, show the revision icon
  // if versionCount > 1 and autoLink exists
  const showRevisionIcon =
    alwaysAllowManualLinks ||
    (versionCount > 1 && onVersionClick && !R.isNil(autoLink));

  // Embedded field ids and the count
  const embeddedFieldIds = R.reject(R.isNil, fields || []);
  const numOfEmbeddedFields = embeddedFieldIds.length;

  const chipWidth = getChipWidth(fieldWidth);

  if (isConversationPrivate) {
    return (
      <HStack
        sx={{
          ...styles.container,
          ...styles.defaultContainer,
          ...styles.titleRow
        }}
      >
        <Text textStyle="privateConversationTitle" as="i">
          Private
        </Text>

        {!disabled && (
          <Icon
            handleClick={titleRowAction}
            type={titleRowActionIcon}
            width={16}
            height={16}
          />
        )}
      </HStack>
    );
  }

  return (
    <>
      {isModalOpen && (
        <Modal
          handleRemove={() => {
            handleRemove(value);
          }}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          title={title}
        />
      )}
      <VStack
        sx={{
          ...styles.container,
          ...styles.verticalContainer,
          ...(expand ? styles.highlightedContainer : styles.defaultContainer)
        }}
      >
        <HStack sx={expand ? styles.titleRowExpanded : styles.titleRow}>
          <WithoutProcessTitle
            id={value}
            style={expand ? styles.expandedTitleContainer : {}}
            disableTitleHyperlink={disableTitleHyperlink}
          />
          <HStack>
            {!showMetaData && showRevisionIcon && (
              <Icon
                type="currentRevision"
                fill={autoLink ? colors.purple : colors.inkLightest}
                onClick={() => onVersionClick?.(value)}
                style={styles.revisionIcon}
              />
            )}

            {(!disabled || expand) && (
              // If the field is locked/disabled then only show the
              // collapse icon and hide the delete icon
              // The div prevents the svg from shrinking when there
              // isn't enough space
              <VStack>
                <Icon
                  handleClick={titleRowAction}
                  type={titleRowActionIcon}
                  width={16}
                  height={16}
                />
              </VStack>
            )}
          </HStack>
        </HStack>
        {showMetaData && (
          <MetaData fieldWidth={fieldWidth && fieldWidth}>
            <Box display="flex" alignItems="start">
              <Status
                roomId={value}
                location="activity-panel"
                chatroomOverlay="activity-panel"
              />
            </Box>
            <Box minW={chipWidth} maxW={chipWidth}>
              <Owner
                roomId={value}
                location="activity-panel"
                chatroomOverlay="activity-panel"
                hideName={fieldWidth && fieldWidth < 360}
              />
            </Box>
            <Box
              display="flex"
              minW={chipWidth}
              w={fieldWidth < 300 && "3.75rem"}
              alignItems="start"
            >
              <DueDate
                roomId={String(value)}
                location="activity-panel"
                fieldWidth={fieldWidth && fieldWidth}
              />
            </Box>
            {showRevisionIcon ? (
              <RevisionButton
                onClick={onVersionClick}
                autoLink={autoLink || false}
                value={value}
                hideName={fieldWidth && fieldWidth < 360 ? true : false}
              />
            ) : (
              <Box w="16px" h="16px"></Box>
            )}
          </MetaData>
        )}
        {numOfEmbeddedFields > 0 && (
          <NestedFields
            fields={embeddedFieldIds}
            roomId={value}
            showFieldNames={showFieldNames}
            expand={expand}
            toggleExpand={toggleExpand}
          />
        )}
      </VStack>
    </>
  );
};

ConversationFieldItem.defaultProps = {
  multiple: false,
  showMetaData: false,
  workflow: null,
  fields: []
};

export default ConversationFieldItem;
