// @flow

import React from "react";
import { Container, Input, Label } from "./styles";

type Props = {
  placeholder?: string,
  onChange: Function,
  value: string | number,
  label?: string
};

const TextInput = ({
  value,
  placeholder = "",
  onChange,
  label = ""
}: Props) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Input
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </Container>
  );
};

export default TextInput;
